<template>
  <div>
    <p>% {{ $t("press") }} (0-50) - {{ $t("looseness") }} (101 - 150)</p>
    <md-field :class="{ 'md-invalid': validation.width.$invalid }">
      <label>{{ $t("width") }}</label>
      <md-input
        :disabled="disabled"
        min="0"
        max="150"
        v-model.number="restrictions.width"
        type="number"
      />
    </md-field>
    <md-field :class="{ 'md-invalid': validation.length.$invalid }">
      <label>{{ $t("length") }}</label>
      <md-input
        :disabled="disabled"
        min="0"
        max="150"
        v-model.number="restrictions.length"
        type="number"
      />
    </md-field>
    <md-field :class="{ 'md-invalid': validation.height.$invalid }">
      <label>{{ $t("height") }}</label>
      <md-input
        :disabled="disabled"
        min="0"
        max="150"
        v-model.number="restrictions.height"
        type="number"
      />
    </md-field>
  </div>
</template>

<script>
export default {
  name: "PackingProfileFormRestrictions",
  props: {
    restrictions: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      required: false,
    },
  },
};
</script>
