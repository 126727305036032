<template>
  <div>
    <div>
      <packing-dispositions-help class="dispositions-help" />
      <b>{{ $t("grid") }}</b>
      <p></p>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.grid"
        value="WLH"
      >
        ABC
      </md-checkbox>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.grid"
        value="LWH"
      >
        BAC
      </md-checkbox>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.grid"
        value="HWL"
      >
        CAB
      </md-checkbox>
    </div>
    <div>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.grid"
        value="WHL"
      >
        ACB
      </md-checkbox>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.grid"
        value="LHW"
      >
        BCA
      </md-checkbox>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.grid"
        value="HLW"
      >
        CBA
      </md-checkbox>
    </div>

    <p>
      <b>{{ $t("cylindrical") }}</b>
    </p>
    <div>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.cylinder"
        value="W"
      >
        W
      </md-checkbox>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.cylinder"
        value="HW"
      >
        HW
      </md-checkbox>
    </div>

    <div>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.cylinder"
        value="L"
      >
        L
      </md-checkbox>
      <md-checkbox
        :class="{ error: validation.$invalid && !disabled }"
        :disabled="disabled"
        v-model="dispositions.cylinder"
        value="HL"
      >
        HL
      </md-checkbox>
    </div>
  </div>
</template>

<script>
import PackingDispositionsHelp from "../utils/PackingDispositionsHelp.vue";
export default {
  components: { PackingDispositionsHelp },
  name: "PackingProfileFormDispositions",
  props: {
    dispositions: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.dispositions-help {
  margin-right: 5px;
}
</style>