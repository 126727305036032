<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-layout"
      :class="maxSize ? 'md-size-100' : 'md-size-70'"
    >
      <packing-profile-form-head
        class="md-layout-item md-size-100"
        :head="form"
        :disabled="readOnly"
        :validation="$v"
      />

      <md-tabs
        class="md-layout-item md-size-100 md-medium-size-100"
        @md-changed="tabChange"
      >
        <md-tab :md-label="$t('article')">
          <packing-profile-form-article
            :article="form.packingArticle"
            :disabled="readOnly"
          />
        </md-tab>
        <md-tab :md-label="$t('restrictions')">
          <packing-profile-form-restrictions
            :restrictions="form.packingRestrictions"
            :disabled="readOnly"
            :validation="$v.form.packingRestrictions"
          />
        </md-tab>
        <md-tab :md-label="$t('others')">
          <packing-profile-form-other
            :other="form.packingOther"
            :disabled="readOnly"
            :validation="$v.form.packingOther"
          />
        </md-tab>
        <md-tab :md-label="$t('dispositions')">
          <packing-profile-form-dispositions
            :dispositions="form.packingDispositions"
            :disabled="readOnly"
            :validation="$v.form.packingDispositions"
          />
        </md-tab>
      </md-tabs>

      <div class="md-layout-item md-size-100" v-if="showSubmitBtn && !readOnly">
        <md-button
          class="md-raised mg-left-0"
          @click="submit"
          :disabled="disabled"
        >
          {{ $t(submitTxt) }}
        </md-button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { ALERT } from "../../store/actions.types";
import PackingProfileFormArticle from "./PackingProfileFormArticle.vue";
import PackingProfileFormDispositions from "./PackingProfileFormDispositions.vue";
import PackingProfileFormHead from "./PackingProfileFormHead.vue";
import PackingProfileFormOther from "./PackingProfileFormOther.vue";
import PackingProfileFormRestrictions from "./PackingProfileFormRestrictions.vue";

const packingDispositionsValidation = (value) => {
  let isValid = value.grid.length > 0 || value.cylinder.length > 0;
  return isValid === true;
};

export default {
  name: "PackingProfileForm",
  components: {
    PackingProfileFormHead,
    PackingProfileFormArticle,
    PackingProfileFormDispositions,
    PackingProfileFormRestrictions,
    PackingProfileFormOther,
  },
  props: {
    profile: {
      type: Object,
      default: function () {
        return {
          packingArticle: {},
          packingRestrictions: {
            width: 0,
            length: 0,
            height: 0,
          },
          packingOther: {
            minArticles: 0,
            minRatio: 1,
            maxIterations: 2,
            toleranceRatio: 0,
            maxResultPerBox: 0,
            maxBoxNumber: 0,
          },
          packingDispositions: {
            grid: ["WLH", "LWH", "HWL", "WHL", "LHW", "HLW"],
            cylinder: ["W", "HW", "L", "HL"],
          },
          boxes: [],
        };
      },
    },
    submitTxt: {
      type: String,
      default: "submit",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    showSubmitBtn: {
      type: Boolean,
      default: true,
    },
    maxSize: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      tabSelected: "",
      form: {},
    };
  },

  validations: {
    form: {
      name: {
        required,
      },
      packingRestrictions: {
        width: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(150),
        },
        length: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(150),
        },
        height: {
          required,
          minValue: minValue(0),
          maxValue: maxValue(150),
        },
      },
      packingOther: {
        minArticles: {
          required,
          minValue: minValue(0),
        },
        maxArticles: {
          required,
          minValue: minValue(0),
        },
        exactArticles: {
          required,
          minValue: minValue(0),
        },
        minRatio: {
          required,
          minValue: minValue(0),
        },
        maxIterations: {
          required,
          minValue: minValue(0),
        },
        toleranceRatio: {
          required,
          minValue: minValue(0),
        },
        maxResultPerBox: {
          required,
          minValue: minValue(0),
        },
        maxBoxNumber: {
          required,
          minValue: minValue(0),
        },
      },
      packingDispositions: {
        packingDispositionsValidation,
      },
    },
  },

  watch: {
    profile: function () {
      this.formatData();
    },
  },

  beforeMount() {
    this.formatData();
  },

  methods: {
    formatData() {
      this.form = JSON.parse(JSON.stringify(this.profile));
    },

    submit() {
      if (this.$v.$invalid) {
        this.$store.dispatch(ALERT, { message: "verify_data" });
      } else {
        this.$emit("onSubmit", this.form);
      }
    },

    onBoxSelect(boxList) {
      this.form.boxes = boxList;
    },

    tabChange(tab) {
      this.tabSelected = tab;
    },
  },
};
</script>

<style scoped>
.md-field >>> .md-textarea {
  max-height: unset;
}
</style>