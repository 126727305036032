<template>
  <div>
    <md-checkbox :disabled="disabled" v-model="article.articleIgnoreMaxWeight">
      {{ $t("article_ignore_max_weight") }}
    </md-checkbox>
  </div>
</template>

<script>
export default {
  name: "PackingProfileFormArticle",
  props: {
    article: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
};
</script>
