<template>
  <div class="md-layout md-alignment-top-space-between">
    <div class="md-layout-item md-size-45">
      <md-field :class="{ 'md-invalid': validation.maxResultPerBox.$invalid }">
        <label> {{ $t("max_result_box") }}</label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.maxResultPerBox"
          type="number"
        />
      </md-field>

      <md-field :class="{ 'md-invalid': validation.minRatio.$invalid }">
        <label> {{ $t("min_ratio") }} (1-100)</label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.minRatio"
          type="number"
        />
      </md-field>

      <md-field :class="{ 'md-invalid': validation.minArticles.$invalid }">
        <label> {{ $t("min_articles") }} {{ $t("inactive_0") }}</label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.minArticles"
          type="number"
        />
      </md-field>

      <md-field :class="{ 'md-invalid': validation.exactArticles.$invalid }">
        <label> {{ $t("articles_exact_quantity") }} {{ $t("inactive_0") }} </label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.exactArticles"
          type="number"
        />
      </md-field>
    </div>

    <div class="md-layout-item md-size-45">
      <md-field :class="{ 'md-invalid': validation.maxBoxNumber.$invalid }">
        <label> {{ $t("max_box_number") }}</label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.maxBoxNumber"
          type="number"
        />
      </md-field>

      <md-field :class="{ 'md-invalid': validation.toleranceRatio.$invalid }">
        <label> {{ $t("tolerance_ratio") }}</label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.toleranceRatio"
          type="number"
        />
      </md-field>

      <md-field :class="{ 'md-invalid': validation.maxArticles.$invalid }">
        <label> {{ $t("max_articles") }} {{ $t("inactive_0") }}</label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.maxArticles"
          type="number"
        />
      </md-field>

      <md-field :class="{ 'md-invalid': validation.maxIterations.$invalid }">
        <label> {{ $t("max_iterations") }}</label>
        <md-input
          :disabled="disabled"
          min="0"
          max="2147483647"
          v-model.number="other.maxIterations"
          type="number"
        />
      </md-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackingProfileFormOther",
  props: {
    other: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
      required: true,
    },
  },
};
</script>
