<template>
  <div class="help">
    <font-awesome-icon
      @click="showDialog = true"
      class="help-icon"
      :icon="['far', 'question-circle']"
    />

    <md-dialog :md-active.sync="showDialog">
      <md-dialog-content>
        <div class="help-content">
          <p class="md-headline">{{ $t("dispositions") }}</p>
          <img src="../../assets/help/disposiciones.jpeg" />
          <p class="md-headline">{{ $t("distributions") }}</p>
          <img src="../../assets/help/distribuciones.jpeg" />
          <p class="md-headline">Ejemplo disposiciones cilíndricas</p>
          <img src="../../assets/help/ejcilindrico.png" />
          <p class="md-headline">Disposiciones cilíndricas</p>
          <img src="../../assets/help/dispcilindricas.png" />
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="showDialog = false">
          {{ $t("close") }}
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
export default {
  name: "PackingDispositionsHelp",
  data() {
    return {
      showDialog: false,
    };
  },
};
</script>

<style>
.help {
  display: inline-block;
}

.help-icon {
  cursor: help;
  font-size: 16px;
}

.help-content img {
  max-width: 500px;
}
</style>