<template>
  <div>
    <md-field :class="{'md-invalid':validation.form.name.$invalid}">
      <label> {{ $t("name") }} </label>
      <md-input v-model="head.name" :disabled="disabled" />
    </md-field>

    <md-field>
      <label> {{ $t("description") }} </label>
      <md-textarea v-model="head.description" :disabled="disabled" />
    </md-field>
  </div>
</template>

<script>
export default {
  name: "PackingProfileFormHead",
  props: {
     head: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Object,
      required: true
    }
  },
};
</script>
